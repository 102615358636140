export const API_LEVEL = '3'

const TemplateStatus = {
  DRAFT: 'template.statusDraft',
  OPEN: 'template.statusOpen',
  BLOCKED: 'template.statusBlocked',
  REMOVED: 'template.statusRemoved'
};

const DossierStatus = {
  OPEN: 'dossier.statusOpen',
  CLOSED: 'dossier.statusClosed',
};

const UserEnabled = {
  true: 'common.yes',
  false: 'common.no'
};

const ContactType = {
  PERSON: 'contact.person',
  COMPANY: 'contact.company'
};

const FieldType = {
  TEXT: 'field.fieldTypeText',
  NUMBER: 'field.fieldTypeNumber',
  DATE: 'field.fieldTypeDate',
  CURRENCY: 'field.fieldTypeCurrency',
  CHECKBOX: 'field.fieldTypeCheckbox',
  RADIO: 'field.fieldTypeRadio',
  LIST: 'field.fieldTypeList',
  MULTILINE_TEXT: 'field.fieldTypeMultilineText',
  CONSENT: 'field.fieldTypeConsent',
  TEMPLATE_RESULT: 'field.fieldTypeTemplateResult',
  UPLOAD: 'field.fieldTypeUpload',
  UPLOAD_MULTIPLE: 'field.fieldTypeUploadMultiple',
  UPLOAD_SIGNED: 'field.fieldTypeUploadSigned',
  HEADER: 'field.fieldTypeHeader',
  GROUP: 'field.fieldTypeGroup',
  COLLECTION: 'field.fieldTypeCollection',
  CONTACT: 'field.fieldTypeContact',
  LEGAL_FORM: 'field.fieldTypeLegalForm',
  CALC: 'field.fieldTypeCalc',
  CALC_VISIBLE: 'field.fieldTypeCalcVisible'
};

const AcceptTypes = {
  '': 'field.fileAnyType',
  'image/*': 'field.onlyImages',
  'image/*,application/pdf': 'field.imagesAndPdf'
};

const LanguageMap = {
  en: 'lang.english',
  nl: 'lang.dutch',
  fr: 'lang.french',
  de: 'lang.german',
  es: 'lang.spanish',
  it: 'lang.italian'
};

export const RoleMap = {
  BASIC_USER: 'user.user_basic',
  USER: 'user.user_functional',
  USER_VIEW: 'user.user_view',
  ADMIN: 'user.user_admin'
};

const CURRENCIES = [
  { code: 'EUR', symbol: '€' },
  { code: 'USD', symbol: '$' }
];

export const DEFAULT_LANG = 'nl';
export const ROOT_UID = '88888888-8888-8888-8888-888888888888';

const AdminPulseDocTypes = {
  11: "field.adminPulseDocTypeAssignmentLetter",
  12: "field.adminPulseDocTypeWrittenPowerOfAttorney",
  29: "field.adminPulseDocTypeGeneratedDocument",
}

export default {
  TemplateStatus,
  UserEnabled,
  DossierStatus,
  ContactType,
  FieldType,
  AcceptTypes,
  LanguageMap,
  RoleMap,
  CURRENCIES,
  ROOT_UID,
  AdminPulseDocTypes
};
